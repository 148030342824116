import React from "react";
import { Card } from "@themesberg/react-bootstrap";
import { getMerchant } from "../../controller/fromApi";

const DisbursementPage = () => {
  const [merchant, setMerchant] = React.useState(null);
  const [officer, setOfficer] = React.useState(null);

  React.useEffect(() => {
    if (!localStorage.getItem("details")) {
      return (window.location.href = "/");
    }
    const merch = JSON.parse(localStorage.getItem("details")).merchant;
    setOfficer(JSON.parse(localStorage.getItem("details")).officer);
    getMerchant(merch._id).then((res) => {
      if (res.status === 200) {
        setMerchant(res.response);
      } else {
        alert(res.message);
      }
    });
  }, []);

  const styles = {
    align: {
      display: "flex",
      alignItems: "baseline",
      padding: 0,
      margin: 0,
    },
    text: {
      padding: 0,
      marginLeft: 5,
      marginTop: 0,
      marginBottom: 0,
      fontWeight: "bold",
    },
    clm: {
      width: "33%",
    },
    head: {
      fontWeight: "bold",
      fontSize: 11,
      opacity: 0.6,
      margin: 0,
    },
    val: {
      fontWeight: "bold",
      fontSize: 12,
      margin: 0,
    },
  };

  return (
    <>
      {merchant ? (
        <>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm mb-5"
          >
            <Card.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <div style={styles.clm}>
                  <p style={styles.head}>TILL NUMBER</p>
                  <p style={styles.val}>{merchant.num}</p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT NAME</p>
                  <p style={styles.val}>{merchant.name.toUpperCase()}</p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT PHONE</p>
                  <p style={styles.val}>+{merchant.phone}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT BANK</p>
                  <p style={styles.val}>
                    {merchant.bank?.name} - {merchant.bank?.account}
                  </p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>TILL BALANCE</p>
                  <p style={{ ...styles.val, color: "green" }}>
                    TZS {merchant.balance.toLocaleString()}
                  </p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>TILL CHARGES</p>
                  <p style={{ ...styles.val, color: "red" }}>
                    TZS {(merchant.balance * 0.03).toLocaleString()}
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        <p>Fetching data...</p>
      )}
    </>
  );
};

export default DisbursementPage;
