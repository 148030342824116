import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faSignOutAlt,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  InputGroup,
  Button,
} from "@themesberg/react-bootstrap";
import Profile3 from "../assets/img/pages/logo.png";

export default (props) => {
  const [officer, setOfficer] = React.useState(null);
  const [company, setCompany] = React.useState(null);

  React.useEffect(() => {
    if (!localStorage.getItem("details")) {
      return (window.location.href = "/");
    }
    setOfficer(JSON.parse(localStorage.getItem("details")).officer);
    setCompany(JSON.parse(localStorage.getItem("details")).merchant);
  }, []);

  const logOut = () => {
    localStorage.removeItem("details");
    window.location.reload();
    return false;
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex justify-content-start w-100">
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer", marginBottom: 20 }}
              onClick={() =>
                (window.location.href = "/deonpay/#/dashboard/overview")
              }
            >
              <Form className="navbar-search">
                {company ? (
                  <Button variant="black" size="sm" className="me-2">
                    <FontAwesomeIcon icon={faBuilding} className="me-1" />{" "}
                    Dashboard
                  </Button>
                ) : null}
              </Form>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer", marginBottom: 20 }}
              onClick={() => (window.location.href = "/deonpay/#/disbursement")}
            >
              <Form className="navbar-search">
                <Button variant="blue" size="sm" className="me-2">
                  <FontAwesomeIcon icon={faMoneyBill} className="me-1" />{" "}
                  Disbursement
                </Button>
              </Form>
            </div>
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="media-body ms-2 text-dark  align-items-center d-none d-lg-block">
                    {officer ? (
                      <span className="mb-0 font-small fw-bold">
                        {officer.name.toUpperCase()}
                      </span>
                    ) : null}
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item
                  onClick={() => (window.location.href = "/deonpay/#/settings")}
                  className="fw-bold"
                >
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My
                  Profile
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item onClick={logOut} className="fw-bold">
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />{" "}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
