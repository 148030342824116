import React from "react";
import Modal from "react-modal";
import { Card } from "@themesberg/react-bootstrap";
import {
  getMerchant,
  getTodayTransactions,
  getMerchantTransactions,
  getRangeTransactions,
  searchTransByPhone,
} from "../../controller/fromApi";

import { TransactionsTable } from "../../components/Tables";

Modal.setAppElement("#root");

export default () => {
  let subtitle;
  const [merchant, setMerchant] = React.useState(null);
  const [officer, setOfficer] = React.useState(null);
  const [ogTrans, setogTrans] = React.useState([]);
  const [allTrans, setAllTrans] = React.useState(null);
  const [search, setSearch] = React.useState(null);

  React.useEffect(() => {
    if (!localStorage.getItem("details")) {
      return (window.location.href = "/");
    }
    const merch = JSON.parse(localStorage.getItem("details")).merchant;
    setOfficer(JSON.parse(localStorage.getItem("details")).officer);
    getMerchant(merch._id).then((res) => {
      if (res.status === 200) {
        setMerchant(res.response);
      } else {
        alert(res.message);
      }
    });
    // getMerchantTransactions(merch.num)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setAllTrans(res.response);
    //     }
    //   })
    //   .catch((err) => console.log(err));

    getTodayTransactions(merch.num)
      .then((res) => {
        if (res.status === 200) {
          setAllTrans(res.response);
          setogTrans(res.response);
        } else {
          setAllTrans([]);
        }
      })
      .catch();
  }, []);

  const getRange = async (entry) => {
    try {
      const body = { start: entry[0], end: entry[1] };
      const respo = await getRangeTransactions(body, merchant.num);
      if (respo.status === 200) {
        return setAllTrans(respo.response);
      } else {
        return setAllTrans([]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onSearch = async (e) => {
    try {
      if (e === "") {
        return setAllTrans(ogTrans);
      }

      const backfeed = await searchTransByPhone(e, merchant.num);
      return setAllTrans(backfeed);
    } catch (error) {
      console.log({ err: error });
    }
  };

  const styles = {
    align: {
      display: "flex",
      alignItems: "baseline",
      padding: 0,
      margin: 0,
    },
    text: {
      padding: 0,
      marginLeft: 5,
      marginTop: 0,
      marginBottom: 0,
      fontWeight: "bold",
    },
    clm: {
      width: "33%",
    },
    head: {
      fontWeight: "bold",
      fontSize: 11,
      opacity: 0.6,
      margin: 0,
    },
    val: {
      fontWeight: "bold",
      fontSize: 12,
      margin: 0,
    },
  };

  return (
    <>
      {merchant ? (
        <>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm mb-5"
          >
            <Card.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <div style={styles.clm}>
                  <p style={styles.head}>TILL NUMBER</p>
                  <p style={styles.val}>{merchant.num}</p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT NAME</p>
                  <p style={styles.val}>{merchant.name.toUpperCase()}</p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT PHONE</p>
                  <p style={styles.val}>+{merchant.phone}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT BANK</p>
                  <p style={styles.val}>
                    {merchant.bank?.name} - {merchant.bank?.account}
                  </p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>TILL BALANCE</p>
                  <p style={{ ...styles.val, color: "green" }}>
                    TZS {merchant.balance.toLocaleString()}
                  </p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>TILL CHARGES</p>
                  <p style={{ ...styles.val, color: "red" }}>
                    TZS {(merchant.balance * 0.03).toLocaleString()}
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>

          <div>
            {allTrans && Array.isArray(allTrans) ? (
              <TransactionsTable
                transactions={allTrans}
                merchant={merchant}
                calenderChange={(e) => getRange(e)}
                phoneSearch={onSearch}
              />
            ) : (
              <p>Loading table...</p>
            )}
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};
