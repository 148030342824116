import React from "react";
import Modal from "react-modal";
import { Form, Card, Button } from "@themesberg/react-bootstrap";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTrafficTable } from "../../components/Tables";
import {
  getMerchantOders,
  approveOrder,
  cancelOrder,
  getMerchant,
} from "../../controller/fromApi";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [allorders, setAllorders] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [merchant, setMerchant] = React.useState(null);
  const [officer, setOfficer] = React.useState(null);
  const [err, setErr] = React.useState(null);

  React.useEffect(() => {
    if (!localStorage.getItem("details")) {
      return (window.location.href = "/");
    }
    const merch = JSON.parse(localStorage.getItem("details")).merchant;
    setOfficer(JSON.parse(localStorage.getItem("details")).officer);
    setCompany(merch);
    getMerchant(merch._id).then((res) => {
      if (res.status === 200) {
        setMerchant(res.response);
      } else {
        alert(res.message);
      }
    });
    getMerchantOders(merch._id)
      .then((res) => {
        if (res.status === 200) {
          setAllorders(res.response);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const onDelete = async (id) => {
    try {
      setIsOpen(true);
      const feedback = await cancelOrder(id, company._id);
      setIsOpen(false);
      if (feedback.status === 202) {
        alert("Order has been canceled");
        return window.location.reload();
      } else {
        return setErr(feedback.message);
      }
    } catch (error) {
      setIsOpen(false);
      console.log(error);
    }
  };

  const onApproved = async (id) => {
    try {
      setIsOpen(true);
      const feedback = await approveOrder(id);
      setIsOpen(false);
      if (feedback.status === 202) {
        alert("Order has been approved");
        return window.location.reload();
      } else {
        return setErr(feedback.message);
      }
    } catch (error) {
      setIsOpen(false);
      console.log(error);
    }
  };

  const styles = {
    align: {
      display: "flex",
      alignItems: "baseline",
      padding: 0,
      margin: 0,
    },
    text: {
      padding: 0,
      marginLeft: 5,
      marginTop: 0,
      marginBottom: 0,
      fontWeight: "bold",
    },
    clm: {
      width: "33%",
    },
    head: {
      fontWeight: "bold",
      fontSize: 11,
      opacity: 0.6,
      margin: 0,
    },
    val: {
      fontWeight: "bold",
      fontSize: 12,
      margin: 0,
    },
  };

  return (
    <>
      {merchant ? (
        <>
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm mb-5"
          >
            <Card.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <div style={styles.clm}>
                  <p style={styles.head}>TILL NUMBER</p>
                  <p style={styles.val}>{merchant.num}</p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT NAME</p>
                  <p style={styles.val}>{merchant.name.toUpperCase()}</p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT PHONE</p>
                  <p style={styles.val}>+{merchant.phone}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={styles.clm}>
                  <p style={styles.head}>MERCHANT BANK</p>
                  <p style={styles.val}>
                    {merchant.bank?.name} - {merchant.bank?.account}
                  </p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>TILL BALANCE</p>
                  <p style={{ ...styles.val, color: "green" }}>
                    TZS {merchant.balance.toLocaleString()}
                  </p>
                </div>
                <div style={styles.clm}>
                  <p style={styles.head}>TILL CHARGES</p>
                  <p style={{ ...styles.val, color: "red" }}>
                    TZS {(merchant.balance * 0.03).toLocaleString()}
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
          <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-xl-0">
              <h4>Disbursements</h4>
              {allorders && Array.isArray(allorders) ? (
                <p className="mb-0">
                  All of your balance withdraw requests are shown here
                </p>
              ) : (
                <p className="mb-0" style={{ color: "orange" }}>
                  You don't have any withdraw requests so far.
                </p>
              )}

              {err ? <p style={{ color: "red" }}>{err}</p> : null}
            </div>
            <Button
              variant="outline-primary"
              size="sm"
              style={{ width: 100 }}
              onClick={() => console.log("disbursehere")}
            >
              <FontAwesomeIcon icon={faMoneyCheck} /> Disburse
            </Button>
          </div>

          {allorders && Array.isArray(allorders) ? (
            <PageTrafficTable
              disbursement={allorders}
              company={company.username}
              officer={officer}
              onApprove={onApproved}
              onDelete={onDelete}
            />
          ) : null}

          <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            contentLabel="Example1 Modal"
          >
            <Form className="mt-4">
              <p>Loading...</p>
            </Form>
          </Modal>
        </>
      ) : (
        <p>Fetching data...</p>
      )}
    </>
  );
};
