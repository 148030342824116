import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCog, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { getMerchantTransactions, getReco } from "../controller/fromApi";
import { TransactionsTable } from "../components/Tables";

export default () => {
  const [allTrans, setAllTrans] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [searchData, setSearchData] = React.useState(null);

  React.useEffect(() => {
    if (!localStorage.getItem("details")) {
      return (window.location.href = "/");
    }
    const merch = JSON.parse(localStorage.getItem("details")).merchant;

    getMerchantTransactions(merch.num)
      .then((res) => {
        if (res.status === 200) {
          setAllTrans(res.response);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSearch = async () => {
    try {
      if (!search) {
        return alert("Enter reference ID");
      }
      const feedback = await getReco(search);
      if (feedback.mobileNo) {
        setSearchData(feedback);
      } else {
        alert("There is no transaction found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const styles = {
    align: {
      display: "flex",
      alignItems: "baseline",
      padding: 0,
      margin: 0,
    },
    text: {
      padding: 0,
      marginLeft: 5,
      marginTop: 0,
      marginBottom: 0,
      fontWeight: "bold",
    },
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Transactions</h4>
          {allTrans && Array.isArray(allTrans) ? (
            <p className="mb-0">Your transaction analytics dashboard.</p>
          ) : (
            <p className="mb-0" style={{ color: "orange" }}>
              There is no any transaction yet
            </p>
          )}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search ref ID"
                onChange={(e) => {
                  setSearchData(null);
                  setSearch(e.target.value);
                }}
              />
              <InputGroup.Text
                style={{ background: "yellow", cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faSearch} onClick={handleSearch} />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
      {searchData && search ? (
        <div
          style={{
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            borderRadius: 5,
            padding: 10,
          }}
        >
          <div style={styles.align}>
            <label>Ref ID:</label>
            <p style={styles.text}>{searchData.TransID}</p>
          </div>
          <div style={styles.align}>
            <label>Network Trans ID:</label>
            <p style={styles.text}>{searchData.ThirdPartyReference}</p>
          </div>
          <div style={styles.align}>
            <label>Amount:</label>
            <p style={styles.text}>{searchData.amount}</p>
          </div>
          <div style={styles.align}>
            <label>Paid:</label>
            {searchData.TransactionStatus === "Success" ? (
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  padding: 0,
                  marginLeft: 5,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                True
              </p>
            ) : (
              <p
                style={{
                  color: "red",
                  fontWeight: "bold",
                  padding: 0,
                  marginLeft: 5,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                False
              </p>
            )}
          </div>
          <div style={styles.align}>
            <label>Mobile No:</label>
            <p style={styles.text}>{searchData.mobileNo}</p>
          </div>
        </div>
      ) : (
        <div>
          {allTrans && Array.isArray(allTrans) ? (
            <TransactionsTable transactions={allTrans} />
          ) : null}
        </div>
      )}
    </>
  );
};
