import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUnlockAlt,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../config/api";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import BgImage from "../../assets/img/illustrations/signin.svg";

export default () => {
  const [username, setUsername] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isSubmiting, setIsSubmiting] = React.useState(false);

  React.useEffect(() => {
    if (localStorage.getItem("details")) {
      return (window.location.href = "/deonpay/#/dashboard/overview");
    }
  }, []);

  const login = async () => {
    try {
      if (!password || !email) {
        return setError("Fill all fields");
      }

      setIsSubmiting(true);
      const data = {
        username: "luico",
        password,
        email,
      };
      const feedback = await api.post("/merchant/signin", data);
      console.log(feedback.data);
      setIsSubmiting(false);
      if (feedback.data.status !== 200) {
        return setError(feedback.data.message);
      }
      await localStorage.setItem("details", JSON.stringify(feedback.data));
      return (window.location.href = "/deonpay/#/dashboard/overview");
    } catch (error) {
      setIsSubmiting(false);
      console.log(error);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h4 className="mb-0">
                    Sign in to{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      TMW SALES
                    </span>{" "}
                  </h4>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-2">
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => {
                          setError(null);
                          setEmail(e.target.value);
                        }}
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="password" className="mb-4">
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => {
                          setError(null);
                          setPassword(e.target.value);
                        }}
                        required
                        type="password"
                        placeholder="Password"
                      />
                    </InputGroup>
                  </Form.Group>
                  {/* <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Remember me
                        </FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">
                        Lost password?
                      </Card.Link>
                    </div> */}

                  {isSubmiting ? (
                    <Button
                      className="w-100"
                      style={{
                        backgroundColor: "#574BFF",
                        borderWidth: 0,
                        height: 50,
                      }}
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button
                      onClick={login}
                      type="submit"
                      className="w-100"
                      style={{
                        backgroundColor: "#574BFF",
                        borderWidth: 0,
                        height: 50,
                      }}
                    >
                      Sign in
                    </Button>
                  )}
                </Form>
                {error ? (
                  <div style={{ margin: 10 }}>
                    <p style={{ color: "red" }}>{error}</p>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <p style={{ textAlign: "center", marginTop: 50, opacity: 0.3 }}>
            {"\u00A9"} TMW Sales Management {new Date().getFullYear()}
          </p>
        </Container>
      </section>
    </main>
  );
};
