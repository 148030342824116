import React from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import { ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";
import { updateOfficerPassword } from "../controller/fromApi";

export default () => {
  const [officer, setOfficer] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const [pass1, setPass1] = React.useState(null);

  React.useEffect(() => {
    if (!localStorage.getItem("details")) {
      return (window.location.href = "/");
    }
    setOfficer(JSON.parse(localStorage.getItem("details")).officer);
    setCompany(JSON.parse(localStorage.getItem("details")).merchant);
  }, []);

  const handleChangePassword = async () => {
    try {
      if (pass !== pass1) {
        return alert("Passwords do not match");
      }
      if (pass.length < 6) {
        return alert("Password should have a length of 6 or more");
      }
      const body = {
        company: company._id,
        email: officer.email,
        password: pass,
      };
      console.log({ body });
      const feedback = await updateOfficerPassword(body);
      alert(feedback.message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} xl={8}>
          <GeneralInfoForm
            passOne={(e) => setPass(e.target.value)}
            passTwo={(e) => setPass1(e.target.value)}
            onSubmit={handleChangePassword}
          />
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              {company && officer ? (
                <ProfileCardWidget
                  name={officer.name}
                  position={officer.position}
                  company={company.name}
                  username={company.username}
                  email={officer.email}
                />
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
