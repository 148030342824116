import api from "../config/api";

export const getMerchant = async (id) => {
  try {
    const merchant = await api.get(`/merchant/${id}`);
    return merchant.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMerchantTransactions = async (num) => {
  try {
    const merchant = await api.get(`/transaction/merchant/${num}`);
    return merchant.data;
  } catch (error) {
    console.log(error);
  }
};

export const searchTransByPhone = async (phone, num) => {
  try {
    const merchant = await api.get(`/transaction/byphone/${phone}/${num}`);
    return merchant.data.response;
  } catch (error) {
    console.log(error);
  }
};

export const getMerchantOders = async (id) => {
  try {
    const orders = await api.get(`/order/merchant/${id}`);
    return orders.data;
  } catch (error) {
    console.log(error);
  }
};

export const createOrder = async (body) => {
  try {
    const orders = await api.post("/order/add/", body);
    return orders.data;
  } catch (error) {
    console.log(error);
  }
};

export const cancelOrder = async (id, merchant) => {
  try {
    const order = await api.put(`/order/cancel/${id}`, { merchant: merchant });
    return order.data;
  } catch (error) {
    console.log(error);
  }
};

export const approveOrder = async (id) => {
  try {
    const order = await api.put(`/order/approve/${id}`);
    return order.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateOfficerPassword = async (body) => {
  try {
    const officer = await api.put("/merchant/password", body);
    return officer.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTodayTransactions = async (num) => {
  try {
    const transactions = await api.get(`/transaction/today/${num}`);
    return transactions.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRangeTransactions = async (body, num) => {
  try {
    const transactions = await api.post(`/transaction/date-range/${num}`, body);
    return transactions.data;
  } catch (error) {
    console.log(error);
  }
};

export const getWeeklyTransactions = async (num) => {
  try {
    const transactions = await api.get(`/transaction/week/${num}`);
    return transactions.data;
  } catch (error) {
    console.log(error);
  }
};

export const getReco = async (ref) => {
  try {
    const order = await api.post("/pay/reco", { refID: ref });
    return order.data;
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (body) => {
  try {
    const response = await api.post("/merchant/password", body);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
