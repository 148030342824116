import React from 'react';


const LogIn = ()=>{
  return(
    <div>
      <p>Hapa ndio logIn</p>
    </div>
  )
}

export default LogIn