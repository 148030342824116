
import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';


export const GeneralInfoForm = (props) => {
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Change Password</h5>
        <Form>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="New password"
                  onChange={props.passOne}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Repeat new password"
                  onChange={props.passTwo}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-3">
            <Button
              onClick={props.onSubmit}
              size="sm"
              variant="primary"
              type="submit"
            >
              Update Now
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
