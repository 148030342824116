import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faSearch,
  faExternalLinkAlt,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Form,
  InputGroup,
  Pagination,
} from "@themesberg/react-bootstrap";

import { pageRanking } from "../data/tables";
import commands from "../data/commands";
import { DateRangePicker } from "rsuite";

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const PageVisitsTable = (props) => {
  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Some Transactions</h5>
          </Col>
          <Col className="text-end">
            <Button
              onClick={() => (window.location.href = "/deonpay/#/transactions")}
              variant="secondary"
              size="sm"
            >
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Description</th>
            <th scope="col">Reference</th>
            <th scope="col">Amount</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {props.trans.slice(0, 10).map((pv) => (
            <tr key={pv.refID}>
              <th scope="row">{pv.description}</th>
              <td>{pv.refID}</td>
              <td>{pv.amount.toLocaleString()}</td>
              <td>
                {new Date(pv.createdAt).toLocaleDateString()}{" "}
                {new Date(pv.createdAt).toLocaleTimeString()}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = (props) => {
  return (
    <Card border="light" className="shadow-sm mb-0">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Account</th>
              <th className="border-0">Amount(TZS)</th>
              <th className="border-0">Claimer</th>
              <th className="border-0">Status</th>
              <th className="border-0">Date</th>
              <th className="border-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {props.disbursement.map((pt) => (
              <tr key={pt._id}>
                <td className="fw-bold" style={{ fontSize: 13 }}>
                  {pt.payment_method.account}-{pt.payment_method.name}
                </td>
                <td>{pt.amount.toLocaleString()}</td>
                <td>{pt.claimer}</td>
                <td
                  style={{
                    fontSize: 12,
                    color:
                      pt.status === "Created"
                        ? "orange"
                        : pt.status === "Approved"
                        ? "blue"
                        : pt.status === "Completed"
                        ? "green"
                        : "red",
                    borderRadius: 10,
                  }}
                >
                  {pt.status.toUpperCase()}
                </td>
                <td>{new Date(pt.createdAt).toLocaleDateString()}</td>
                <td>
                  {props.officer.position === "Manager" &&
                  pt.status === "Created" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => props.onApprove(pt._id)}
                        style={{
                          background: "#3487c7",
                          cursor: "pointer",
                          padding: 5,
                          textAlign: "center",
                          margin: 1,
                          color: "white",
                          borderRadius: 3,
                          fontSize: 12,
                        }}
                      >
                        Approve
                      </div>
                      <div
                        onClick={() => props.onDelete(pt._id)}
                        style={{
                          background: "red",
                          cursor: "pointer",
                          padding: 5,
                          textAlign: "center",
                          margin: 1,
                          color: "white",
                          borderRadius: 3,
                          fontSize: 12,
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        background: "#60cc98",
                        padding: 5,
                        textAlign: "center",
                        margin: 1,
                        color: "white",
                        borderRadius: 3,
                        fontSize: 12,
                      }}
                    >
                      Done
                    </div>
                  )}
                  {props.officer.position !== "Manager" &&
                  pt.status === "Created" ? (
                    <div
                      onClick={() => props.onDelete(pt._id)}
                      style={{
                        background: "red",
                        padding: 5,
                        cursor: "pointer",
                        textAlign: "center",
                        margin: 5,
                        color: "white",
                        borderRadius: 5,
                      }}
                    >
                      Cancel
                    </div>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const {
      country,
      countryImage,
      overallRank,
      overallRankChange,
      travelRank,
      travelRankChange,
      widgetsRank,
      widgetsRankChange,
    } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image
              src={countryImage}
              className="image-small rounded-circle me-2"
            />
            <div>
              <span className="h6">{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{overallRank ? overallRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">{travelRank ? travelRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">{widgetsRank ? widgetsRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map((r) => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = (props) => {
  const { allowedRange } = DateRangePicker;
  const [num, setNum] = React.useState(10);
  const [pages, setPages] = React.useState(null);
  const [activePage, setActivePage] = React.useState(1);
  const [cvalue, setcValue] = React.useState([new Date(), new Date()]);
  const [phoneInput, setphoneInput] = React.useState(null);

  React.useEffect(() => {
    let p = props.transactions?.length / num;
    setPages([...Array(Math.round(p)).keys()]);
  }, [num]);

  const totalAmt = (p) => {
    let total = 0;

    if (p == "all") {
      props.transactions?.forEach((e) => {
        total = total + e.amount;
      });
    } else {
      props.transactions
        ?.slice(num * activePage - num, num * activePage)
        .forEach((e) => {
          total = total + e.amount;
        });
    }

    return total;
  };

  const exportTable = () => {
    const doc = new jsPDF();

    //expor all data

    let data = props.transactions?.map((t, key) => [
      `${num * activePage - num + 1 + key}`,
      `${new Date(t.createdAt).toLocaleDateString()} ${new Date(
        t.createdAt
      ).toLocaleTimeString()}`,
      t.transID,
      t.refID,
      t.user,
      t.amount.toLocaleString(),
    ]);

    //export current page
    // let data = props.transactions
    //   ?.slice(num * activePage - num, num * activePage)
    //   .map((t, key) => [
    //     `${num * activePage - num + 1 + key}`,
    //     `${new Date(t.createdAt).toLocaleDateString()} ${new Date(
    //       t.createdAt
    //     ).toLocaleTimeString()}`,
    //     t.transID,
    //     t.refID,
    //     t.user,
    //     t.amount.toLocaleString(),
    //   ]);
    doc.setFontSize(10);
    autoTable(doc, {
      head: [["SN", "DATE", "TRANS ID", "REF", "PHONE", "AMOUNT"]],
      foot: [
        [
          " ",
          props.merchant.name,
          `Till ${props.merchant.num}`,
          " ",
          " ",
          `TZS ${totalAmt("all").toLocaleString()}`,
        ],
      ],
      body: data,
      theme: "grid",
      font: "times",
      showHead: "everyPage",
      showFoot: "lastPage",
      footStyles: { fillColor: "#565656" },
    });

    return doc.save("deonpay-transactions.pdf");
  };

  return (
    <>
      <Form
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 5,
        }}
      >
        <Form.Group className="mb-2">
          <p style={{ fontWeight: "bold" }}>Today's Sales</p>
          {/* <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Phone number"
              onChange={(e) => setphoneInput(e.target.value)}
            />
            <Button size="sm" onClick={() => props.phoneSearch(phoneInput)}>
              search
            </Button>
          </InputGroup> */}
        </Form.Group>
        <Form.Group className="mb-3">
          <DateRangePicker
            value={cvalue}
            onChange={(e) => {
              setcValue(e);
              props.calenderChange(e);
            }}
            placeholder={`${new Date().toDateString()}`}
            shouldDisableDate={allowedRange(new Date("2024-01-01"), new Date())}
            limitStartYear={1}
            showOneCalendar
          />
        </Form.Group>

        <Form.Group
          className="mb-3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Form.Select
            size="sm"
            style={{ width: 80, marginRight: 5 }}
            onChange={(e) => {
              setNum(Number(e.target.value));
              setActivePage(1);
            }}
          >
            <option defaultValue>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </Form.Select>
          <Button
            variant="outline-primary"
            size="sm"
            style={{ width: 100 }}
            onClick={exportTable}
          >
            <FontAwesomeIcon icon={faCloudDownloadAlt} /> Export
          </Button>
        </Form.Group>
      </Form>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">sn</th>
                <th className="border-bottom">Date</th>
                <th className="border-bottom">Trans ID</th>
                <th className="border-bottom">Ref</th>
                <th className="border-bottom">Phone</th>
                <th className="border-bottom">Amount</th>
                <th className="border-bottom">Status</th>
              </tr>
            </thead>
            {props.transactions?.length > 0 ? (
              <tbody>
                {props.transactions
                  .slice(num * activePage - num, num * activePage)
                  .map((t, key) => (
                    <tr key={t.refID}>
                      <td>
                        <span className="fw-normal">
                          {num * activePage - num + 1 + key}
                        </span>
                      </td>
                      <td>
                        <span className="fw-normal">
                          {new Date(t.createdAt).toLocaleDateString()}{" "}
                          {new Date(t.createdAt).toLocaleTimeString()}
                        </span>
                      </td>
                      <td>
                        <span className="fw-normal">{t.transID}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{t.refID}</span>
                      </td>
                      <td>
                        <span className="fw-normal">{t.user}</span>
                      </td>

                      <td>
                        <span className="fw-normal">
                          {t.amount.toLocaleString()}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`fw-normal text-${
                            t.isSuccess ? "success" : "danger"
                          }`}
                        >
                          {t.isSuccess ? "Successful" : "Unsuccessfully"}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : (
              <tbody>
                <p style={{ marginTop: 20 }}>No transaction available</p>
              </tbody>
            )}
          </Table>

          {props.transactions?.length > 0 ? (
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <p style={{ fontSize: 14, marginTop: 8 }}>
                Showing 1 to {num} of {props.transactions.length} entries
              </p>
              <Nav>
                {pages && pages.length > 0 ? (
                  <Pagination className="mb-2 mb-lg-0 " size="sm">
                    <Pagination.Prev
                      onClick={() =>
                        activePage === 1 ? null : setActivePage(activePage - 1)
                      }
                    >
                      Previous
                    </Pagination.Prev>
                    {pages.map((p, k) => (
                      <Pagination.Item
                        onClick={() => setActivePage(k + 1)}
                        key={k}
                        active={activePage === k + 1 ? true : false}
                      >
                        {k + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Next
                      onClick={() =>
                        activePage === pages.length
                          ? null
                          : setActivePage(activePage + 1)
                      }
                    >
                      Next
                    </Pagination.Next>
                  </Pagination>
                ) : null}
              </Nav>
            </Card.Footer>
          ) : null}
        </Card.Body>
      </Card>
    </>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: "5%" }}>
          <ul className="ps-0">
            {usage.map((u) => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: "50%" }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: "40%" }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table
          responsive
          className="table-centered rounded"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: "5%" }}>
                Name
              </th>
              <th className="border-0" style={{ width: "5%" }}>
                Usage
              </th>
              <th className="border-0" style={{ width: "50%" }}>
                Description
              </th>
              <th className="border-0" style={{ width: "40%" }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map((c) => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
